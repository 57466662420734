.medium {
  font-family: futura-pt, sans-serif;
  font-weight: 500;
  font-style: normal;
}

.light {
  font-family: futura-pt, sans-serif;
  font-weight: 300;
  font-style: normal;
}

.book {
  font-family: futura-pt, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.demi {
  font-family: futura-pt, sans-serif;
  font-weight: 600;
  font-style: normal;
}


.bold {
  font-family: proxima-nova, sans-serif;
  font-weight: 700;
  font-style: normal;
}
.semibold {
  font-family: proxima-nova, sans-serif;
  font-weight: 600;
  font-style: normal;
}

.regular {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: normal;
}

.italic {
  font-family: proxima-nova, sans-serif;
  font-weight: 400;
  font-style: italic;
}

.light {
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-style: normal;
}

.lightitalic {
  font-family: proxima-nova, sans-serif;
  font-weight: 300;
  font-style: italic;
}


html {
  -webkit-font-smoothing: antialiased;
}

body {
  @extend .regular;
  font-size: 1.05rem;
  line-height: 1.4rem;
}

nav {
  text-transform: uppercase;
}

h2 {
  text-transform: uppercase;
  font-size: 2rem;
  @extend .semibold;
  // font-weight: normal;
}

h3 {
  text-transform: uppercase;
  @extend .semibold;
}
.news h3 {
  // font-weight: normal;
}

.footer h3 {
  font-size: 2rem;
}

h5 {
  font-size: 1.1rem;
  @extend .regular;
}

.logo {
  text-transform: uppercase;
  font-size: 2.5rem;
  line-height: 2.25rem;
  @extend .semibold;
  a {
    text-decoration: none;
  }
}

.quote {
  font-size: 2rem;
  line-height: 2.5rem;
}

.mainquote {
  text-transform: uppercase;
  a {text-decoration: none;}
}

.extra {
  font-size: 0.85rem;
  line-height: 1rem;
  @extend .semibold;
}

.news .date {
  font-size: 0.9rem;
}

.cast {
  a {
    text-decoration: none;
    @extend .semibold;
  }
}

.langswitch {
  font-size: 0.9rem;
  line-height: 1rem;
  font-weight: normal;
}

@media only screen and (max-width: 45em) {
  .logo {
    font-size: 1.5rem;
    line-height: 1.5rem;
    padding: 0 25px;
  }
}