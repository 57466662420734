.m-menu {

  position: fixed;
  top:30px;
  right:15px;
  display: none;
  z-index: 122345;

  .mm {
    border:none;
    background:none;
    cursor: pointer;
    position: relative;
    display: block;
    width:26px;
    height:26px;
    outline:none;
    padding: 0; // ios has a bug otherwise

    &:before, &:after {
      position: absolute;
      display: block;
      content:'';
      width:14px;
      height: 2px;
      background-color:$maincolor;
      transition: all 0.15s  cubic-bezier(0.445, 0.050, 0.550, 0.950);
    }
    &:after {
      top: 7px;
    }

  }

}

.m-menu-open {
  .m-menu .mm {
    &:before {
      top:10px;
      transform:rotate(-45deg);
    }
    &:after {
      top:10px;
      transform:rotate(45deg);
    }
  }
  .nav {
    display: block;
  }
}

@media only screen and (max-width: 35em) {
  .m-menu {
    display: block;
  }

  .nav {
    display: none;
  }

}