/* Put your layout here */

.header {
  position: fixed;
  width: 100%;
  top:0;
  background: white;

  .logo {
    // max-width: 250px;
    margin: 0 auto;
    // display: flex;
    // justify-content: center;
    // align-items: baseline;
  }
  .nav {
    margin-top: 20px;
  }
  .langswitch {
    position: absolute;
    top: 20px;
    right: 20px;
    display: flex;
    margin-left: 10px;
    li {
      margin-left: 5px;
      a {
        text-decoration: none;
      }
      .active {
        color: $maincolor;
      }
    }

  }
}
nav li {
  display: inline-block;
  margin: 0 5px;

  a {
    text-decoration: none;
  }
}

.main {
  margin-top: 100px;
}

.offline .main {
  margin-top: 0;
  img {
    margin:0 auto;
  }
}

.main-img img {
  width: 100%;
}

#trailer {
  margin-top: 120px;
}

.quote {
  padding: 40px 80px;
  text-align: center;
}

.mainquote {
  cursor: pointer;
}

.block {
  text-align: left;
}

h2 {
  text-align: center;
}

h3 {
  margin: 11px 0 2px 0;
}

// make sure the title is visible when scrolling to the selected block

.anchor::before {
  display: block;
  content: " ";
  margin-top: -120px;
  height: 120px;
  visibility: hidden;
  pointer-events: none;
}

.inner {
  max-width: 1220px;
  margin: 0 auto;
  text-align: center;
  padding: 20px;

  .blocks {
    display: flex;
    flex-wrap: wrap;
    .block {
      margin: 0 20px 20px 20px;
    }
  }
}

.news {
  .blocks .block {
    margin: 20px;
    width: calc(100% / 3 - 40px);

    h3 {
      margin-top: 15px;
      margin-bottom: 0;
    }

    p {
      margin-top: 5px;
    }
  }
}

.gallery {
  // background:pink;
  img {
    width: 100%;
    height: auto;
  }
}

.slick-slider, #gallery {
  position: relative;
}
.slick-arrow {
  position: absolute;
  bottom: calc(50% - 30px);
  z-index: 2;
  background: transparent;
}
.slick-prev {
  left: 20px;
}
.slick-next {
  right: 20px;
}

.synopsis {
  background: white;
  .inner {
    display: flex;
    .block {
      margin: 0 20px 20px 20px;
      width: calc(100% / 2 - 40px);
      text-align: justify;
    }

    .extra {
      margin-top: 20px;
      margin-bottom: 0;
    }
    .extra:last-child {
      margin-top: 2px;
    }
  }
}

.partners .block {
  width: calc(100% / 3 - 40px);
  background: $highlightcolor;
}

.characters .block {
  display: flex;
  flex-wrap: wrap;
  .character {
    margin: 10px;
    width: calc(25% - 20px);

    h3 {
      font-size: 1.1rem;
      a {
        text-decoration: none;
      }
    }
  }
}

.cast {
  background-color: white;
}

.cast .block, .footer .block {
  width: calc(100% / 3 - 40px);
  margin: 0 20px 20px 20px;
  h2 {
    text-align: left;
  }
}

.directors {
  background: white;
  .block {
    width: calc(100% / 2 - 40px);
    text-align: justify;
    .thumb {
      margin-top: 20px;
    }
  }
}



.footer {
  h3 {
    margin-top: 40px;
    margin-bottom: 40px;
  }
  p {
    margin-bottom: 40px;
  }

  .b1 {
    width: calc(75% - 40px);
    p {
      display: inline-block;
      vertical-align: top;
      width: calc(50% - 4px);
      margin-top: 0;
    }
  }
  .b2 {
    width: calc(25% - 40px);
  }
}
.footer .inner, .cast .inner {
  display: flex;
  flex-wrap: wrap;
}

.footer .block {
  // margin: 20px 80px 20px 20px;
}

@media only screen and (max-width: 60em) {
  .news .blocks .block {
    width: calc(50% - 40px);
  }
  .cast .block, .footer .block {
    width: calc(50% - 40px);
  }
  .characters .block {
    .character {
      width: calc(50% - 20px);
    }
  }

  .footer .block {
     width: calc(100% - 40px);
  }
}
@media only screen and (max-width: 45em) {

  main.main {
    margin-top: 57px;
  }

  .inner {
    flex-direction: column;
  }
  .block {
    width: 100% !important;
    margin: 0 !important;
    margin-bottom: 20px !important;
  }

  .quote {
    padding-left: 20px;
    padding-right: 20px;
  }

  .characters .block {
    .character {
      width: calc(100%);
      margin-left: 0;
      margin-right: 0;
    }
  }

  .footer .block p {
    display: block;
  }

}

@media only screen and (max-width: 35em) {
  .header .langswitch {
    left: 0;
    right: 0;
    top: 10px;
    margin: 0 auto;
    width: 100%;
    justify-content: center;
    margin: 0;
  }
  .header {
    padding-top: 10px;
  }
  #trailer {
    margin-top: 70px;
  }
}