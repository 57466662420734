/* Put your global styles here */
@import 'normalize';
@import 'boilerplate';
@import 'variables';
@import 'typo';
@import 'layout';
@import 'nav';

body {
  background-color: $bgcolor;
  color: $maincolor;
}

a {
  color: $maincolor;
}

ul,
li {
  list-style: none;
  margin:0;
  padding:0;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
}

.active, a:hover{
  color: $highlightcolor;
}

.quote, .footer {
  background-color: $maincolor;
  color: white;
  a {
    color: white;
  }
}

.slick-arrow {
  width: 80px;
  height: 80px;
  border: 0;
  color: transparent;
  padding: 0;
  outline: none;
  transform: scale(0.75);

  &:before {
      position: absolute;
      top:calc(50% - 40px);
      left: 0;
      right: 0;
      margin:0 auto;
      content:'';
      width: 80px;
      height: 80px;
      border:2px solid white;
      border-radius:50%;
    }
    &:after {
      position: absolute;
      top:calc(50% - 12px);
      left: 0;
      right: 10px;
      margin:0 auto;
      content:'';
      width: 24px;
      height: 24px;
      border-right: 2px solid white;
      border-top: 2px solid white;
      transform:rotate(45deg);
    }
  &.prev, &.slick-prev {
    transform: rotate(180deg) scale(0.75);
  }
}
